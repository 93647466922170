footer{
    background-color: white;
    padding: 3rem 2rem;
}
ul{
    padding: 0px;
}
.footer-col-1 {
    display: flex;
    align-items: flex-start;
    grid-gap: 200px;
    max-width: 1000px;
    margin: 0 auto;
}


.flex-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 30px auto;

}

.footer-links{
    display: flex;
    flex-wrap: wrap;
}
.footer-links li{
    margin-bottom: 20px;
}

.footer-links li a{
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #14467C;
}

.footer-col-2 p{
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #14467C;
    
}

.social-icons img{
    margin-right: 30px;
}

@media screen and (max-width:950px) {

    .footer-col-1{
        flex-direction: column;
        grid-gap: 0px;
       
    }

    .flex-row{
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 5px;
        max-width: 100%;
    }

    .footer-col-2 p, .footer-links li a {
        font-size: 16px;
    }

}
