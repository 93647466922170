.timer{
    background-color: #6DCFF6;
    display: flex;
    justify-content: space-around;
    height: 110px;
    align-items: center;
}

.timer-btn{
    width: 100%;
    height: auto;
    background-color: #14467C;
    padding: 1rem 2rem;
    font-size: 16px;
    border-radius: 0px;
}

.timer-clock{
    display: flex;
    align-items: center;
    justify-content: center;
}

.timer-clock section{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem;

}

.timer-clock section p{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #14467C;
    height:40px;
    background-color: white;
    width: 40px;
}

.timer-clock section small{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #14467C;
}

.timer-clock span{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin-top: -15px;
    line-height: 25px;
    color: #14467C;
}


@media screen and (max-width:850px) {
    .close-icon{
        display: none;
    }

    .timer-clock section{
        margin: 0rem;
    }

    .timer-clock section p{
        font-size: 13px;
        width: 30px;
    }

    .timer-btn{
        padding: 1rem 0.5rem;
        font-size: 12px;
    }
    .timer-clock section small{
        font-size: 13px;
    }
}

@media screen and (max-width:450px){

    .timer{
        flex-direction: column;
        height: 100%;
        padding: 1rem;
    }
}