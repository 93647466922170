.finance{
    padding: 3rem 2rem;
    background-image:  linear-gradient(to bottom, rgba(246, 246, 246, 0.75), rgba(250, 250, 250, 0.75)),
    url('../../assets/faq-bg.jpg');
}
.finance-item p{
    background-color: #14467C;
    max-width: 700px;
    padding: 3rem;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    color: #F0EFF4;
    align-items: center;
    justify-content: center;
}

.finance-item {
    margin-bottom: 100px;
}


@media screen and (max-width:550px) {

    .re-order{
        flex-direction: column-reverse;
     }

     .finance-item{
        display: flex;
        grid-gap: 20px;
        justify-content: center;
        align-items: center;
     }

}