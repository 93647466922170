.header-banner{
    background-image: url('../../assets/celphish-bg.jpg');
    background-size: cover;
    background-position: center;
    height: 80vh;
    padding: 3rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.header-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.header-banner h1{
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    text-align: center;
    line-height: 81px;
    color: white;
}
.button-container{
    display: flex;
    grid-gap: 60px;
}

.btn{
    width: 224px;
    height: 83px;
    background: #6DCFF6;
    outline: none;
    border: none;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
}

.display-mobile{
    display: none;
}

@media screen and (max-width:950px) {
    .header-banner {
        height: 40vh;
    }
    .header-banner h1{
     font-size: 25px;
       
    }
    .center{
        display: none;
    }
    .display-mobile{
        display: block;
        padding: 3rem 2rem;
    }
    .display-mobile h1{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 2rem;
        line-height: 30px;
        color: #14467C;

    }
    .button-container{
        grid-gap: 10px;
    }
    .btn{
        width: auto;
        font-size: 20px;
        padding: 1rem;
    }
}