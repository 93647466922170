.text-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
    margin-left: 60px;
}

.text-container::-webkit-scrollbar {
    display: none;
}

.tokenomics-distibution{
    min-width: 800px;
    padding: 2rem;
    margin: 1rem;
    position: relative;
    width: 100%;
    height: 100%;
}


@media screen and (max-width:900px) {

    .text-container{
        margin-left: 0px;
    }
  

    .tokenomics-distibution{
       min-width: 100%;
       padding: 1rem;
       margin: 0rem;
    }


}