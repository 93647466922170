.about-header{
    background-image: url('../../assets/about-banner.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
}

.about-content{
    display: flex;
    max-width: 1300px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    grid-gap: 20px;
    padding: 4rem 2rem;
}

.flex-2fr{
    flex: 2;
    font-weight: 700;
    font-size: 30px;
    line-height: 75px;
    color: #14467C;
}

.flex-5fr{
    flex: 5;
   
}

.flex-5fr p{
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    color: #14467C;
}


@media screen and (max-width:650px) {
    .about-header{
        background-image: url('../../assets/about-mobile.svg');
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        height: 40vh;
        width: 100%;
        margin-bottom: 0px;
    }

    .about-content{
        padding: 2rem 1rem;
        grid-gap: 0px;
        margin: 0px;
    }
}