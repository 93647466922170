.tokenomics{
    padding: 3rem 1rem;
    background-color: #E5E5E5;
}
.line-container{
    display: flex;
    align-items: center;
    grid-gap: 13px;
}
.line{
    width: 48px;
    height: 4px;
    display: block;
    background: #14467C;
}

.token-distribution-wide{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.token-distribution-wide h2{
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 75px;
    color: #14467C;
    margin-bottom:-90px;
}

.token-distribution-small{
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.token-line-container{
    display: flex;
    align-items: flex-start;
    justify-content:flex-start;
    grid-gap: 13px;
}

.token-line-container .line{
    width: 20px;
    margin-top:10px;

}

.token-line-container h3{
    padding: 0;
    margin: 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #14467C;
}

.token-line-container p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #14467C;
}

.flex-5fr h3{
    font-size: 25px;
    color: #14467C;
}


.token-distribution-container > div:nth-of-type(1) .line {
    background-color: #FD6742;
}
.token-distribution-container > div:nth-of-type(2) .line {
    background-color: #FFC857;
}
.token-distribution-container > div:nth-of-type(3) .line {
    background-color: #94D75E;
}
.token-distribution-container > div:nth-of-type(4) .line {
    background-color: #42CAFF;
}
.token-distribution-container > div:nth-of-type(5) .line {
    background-color: #4B76F9;
}
.token-distribution-container > div:nth-of-type(6) .line {
    background-color: #1749A4;
}
.token-distribution-container > div:nth-of-type(7) .line {
    background-color: #F6C36A;
}
.token-distribution-container > div:nth-of-type(8) .line {
    background-color: #072142;
}

.left_arrows {
    cursor: pointer;
    border-radius: 5px;
    transition: .5s ease;
    
}


@media screen and (max-width:550px) {

    .token-distribution-wide{
        display: none;
    }

    .token-distribution-small{
        display: flex;
        padding: 2rem;
    }
    .token-distribution-small img{
        margin-bottom: 30px;
    }

    .flex-5fr p{
        font-size: 16px;
    }

    .flex-5fr h3{
        font-size: 20px;
    }

    .token-line-container{
        margin-bottom: 30px;
    }

}