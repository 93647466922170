body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body, html{
  overflow-x: hidden !important;
}

*{
  box-sizing: border-box;

}


a{
  text-decoration: none;
  color: #14467C;
}

li{
  list-style: none;
}