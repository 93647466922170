.coming-soon{
    background-image:  linear-gradient(to bottom, rgba(246, 246, 246, 0.2), rgba(250, 250, 250, 0.2)),
    url('../../assets/coming-soon.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 80vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.coming-soon h2{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.7em;
    color: #14467C;
    margin-bottom: 4rem;
    text-align: center;
}

.clock{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.clock section{
    background: rgba(217, 217, 217, 0.093);
    border: 3px solid #14467C;
    backdrop-filter: blur(52.03px);
    border-radius: 15px;
    width: 233px;
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clock section p{
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    margin: 0px;
    color: #14467C;

}
.clock section small{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #14467C;

}
.clock  span{
    font-style: normal;
    font-weight: 700;
    font-size: 128px;
    line-height: 160px;
    color: #14467C;
    margin: 20px;
 }

.presale-btn {
    width: 100%;
    background-color: #14467C;
    padding: 1rem 2rem;
    border-radius: 8px;
    font-weight: 700;
    font-size: 25px;
    margin-top: 3rem;
}


@media screen and (max-width:1200px) {

    .clock  span{
        font-size: 68px;
        margin: 20px;
     }
     .clock section{
         width: 200px;
     }

}

@media screen and (max-width:850px) {
    
    .coming-soon{
        background-image:  linear-gradient(to bottom, rgba(246, 246, 246, 0.2), rgba(250, 250, 250, 0.2)),
        url('../../assets/coming-soon-mobile.svg');
        height: auto;
        padding: 3rem 1rem;
        background-size: cover;
        background-position:center;
    }
    .clock section{
        width: 168px;
        height: 173px;
        margin-bottom: 30px;
    }

    .clock{
        flex-direction: column;
    }
    .clock  span{
        display: none;
    }
    .presale-btn{
        font-size: 20px;
    }
}


@media screen and (max-width:450px) {

    .coming-soon h2{
        font-size: 16px;
    }

    .coming-soon{
        background-size: contain;
    }

}