.offerpage{
    padding: 2rem;
    background-image:  linear-gradient(to bottom, rgba(246, 246, 246, 0.75), rgba(250, 250, 250, 0.75)),
    url('../../assets/faq-bg.jpg');
}
.offer-container {
    max-width: 1000px;
}
.offer-content {
    max-width: 500px;
    flex: 3;
}

.offer-content h3{
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 75px;
    color: #14467C;
    margin-bottom: 0px;

}

.offer-content p{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    color: #14467C;
}

.offer-item-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    grid-gap: 40px;
}

.offer-item-1 img{
    width: 100%;
}

@media screen and (max-width:650px){
    .offer-item-container{
        max-width: 100%;
        grid-gap: 30px;
    }
}