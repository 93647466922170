.faq{
    padding: 3rem 1rem;
    background-image:  linear-gradient(to bottom, rgba(3, 32, 63, 0.75), rgba(7, 49, 93, 0.75)),
    url('../../assets/faq-bg.jpg'); ;
}

.faq-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-item{
    border: 3px solid #FFFFFF;
    padding: 2rem;
    border-radius: 20px;
    margin-bottom: 20px;
}
.faq h1{
    color: white;
}
.faq-question h2{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    
}
.faq-text p{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
}

@media screen and (max-width:550px) {

    .faq-question h2{
       font-size: 20px;
    }

    .faq-text p{
        font-size: 16px;
    }

}