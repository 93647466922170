.roadmap{
    background-image:  linear-gradient(to bottom, rgba(246, 246, 246, 0.75), rgba(250, 250, 250, 0.75)),
    url('../../assets/faq-bg.jpg');
    padding: 3rem 1rem
}
.roadmap-container{
    max-width: 1300px;
    margin: 0 auto;
}

.roadmap h1{
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 75px;
    color: #14467C;
}
.roadmap h3, .roadmap h2{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #14467C;
}
.roadmap p{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #899DB4;
}

.roadmap-q1{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 30px;
    /* flex-wrap: wrap; */
}




@media screen and (max-width:650px) {
    .roadmap h3{
        font-size: 20px;
    }
    .roadmap p{
        font-size: 16px;
    }
}