.presale-stage{
    background-image: url('../../assets/faq-bg.jpg');
    background-size: cover;
    padding: 3rem 1rem;
}

.stages-container{
    display: flex;
    max-width: 900px;
    justify-content: space-around;
    margin: 0 auto;
    padding: 2rem 3rem;   
    background: rgba(109, 207, 246, 0.2);
    border-radius: 50px;
    border-bottom: 2px dashed #000;
}

.presale-stage h2{
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    color: #14467C;
    text-align: center;
}

.stage-1 h3{
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    color: #14467C;
}

.stage-1 h6{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #14467C;
    margin-bottom: 0px;
}

.stage-1 p{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
    color: #14467C;
}

.stages-container > div:nth-of-type(1)  {
    border-right: 2px solid #98E2FF;
    padding-right: 50px;
}

.stages-container > div:nth-of-type(2)  {
    border-right: 2px solid #98E2FF;
    padding-right: 50px;
}

.total-token {
    display: flex;
    max-width: 900px;
    justify-content: space-around;
    margin: 0 auto;
    padding: 2rem 3rem;   
    background: rgba(109, 207, 246, 0.2);
    border-radius: 50px;
}

@media screen and (max-width:650px) {

    .stages-container{
        flex-direction: column;
        grid-gap: 0px;
    }
    .stages-container > div:nth-of-type(1)  {
        border-right: 0px;
        border-bottom: 2px solid #98E2FF;
        padding-right: 0px;
    }
    .stages-container > div:nth-of-type(2)  {
        border-right: 0px;
        border-bottom: 2px solid #98E2FF;
        padding-right: 0px;
    }

}