.header {
    width: 100%;
    height: 104px;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #F5F5F5;
}

.header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;

}

.colors {
    color: var(--primary);
}

.nav-menu {
    display: flex;
    align-items: center;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu a {
    font-size: 1rem;
    font-weight: 600;
    color:#BDB8B8;
}


.hamburger {
    display: none;
    cursor: pointer;
}



@media screen and (max-width:1240px) {

    .centremenu {
        position: absolute;
        right: 5vw;
        z-index: 7;
        transition: 0.4s;
    }

    .hamburger {
        display: flex;
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 30px;
        top: 0px;
        right: -100%;
        width: 100%;
        height: 100vh;
        transition: 0.4s;
        z-index: 2;
    }

    .nav-menu.active {
        right: 0;
        background-color:#14467C;
    }

    .nav-menu a {
        font-size: 1.2rem;
        color: white;
        
    }
   
}





