.smartphone-container{
   display: flex;
   max-width: 90%;
   align-items: center;
   justify-content: center;
   margin: 0 auto;
   grid-gap: 60px;
   padding: 2rem 3rem;
}

.smartphone-content h3{
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    color: #14467C;
}

.smartphone-content p{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    color: #14467C;
    
}

.smartphone-img{
    width: 100%;

}



@media screen and (max-width:650px) {

    .smartphone-container{
      padding: 1rem;
      grid-gap: 20px;
       
    }
    .smartphone-content h3{
        font-size: 30px;
    }

    .smartphone-content p{
        font-size: 16px;
    }
   

}